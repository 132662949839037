import "./index.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { supabase } from "./supabaseClient";
import Auth from "./Auth";
import { Layout } from "./Layout";
import { UserProvider } from "./UserContext";
import StationList from "./Stations/StationList";
import InvoiceList from "./Invoices/InvoiceList";

export default function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <div>
      {!session ? (
        <Auth />
      ) : (
        <UserProvider>
          <Router>
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <Layout>
                    <StationList></StationList>
                  </Layout>
                }
              />
              <Route
                path="/invoices"
                exact
                element={
                  <Layout>
                    <InvoiceList></InvoiceList>
                  </Layout>
                }
              />
            </Routes>
          </Router>
        </UserProvider>
      )}
    </div>
  );
}
