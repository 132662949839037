// contexts/User/reducer.js

export const reducer = (state, action) => {
  if (action.site_id) {
    sessionStorage.setItem("currentSiteId", parseInt(action.site_id));
    return {
      ...state,
      currentSiteId: action.site_id,
    };
  } else {
    return state;
  }
};

export const initialState = {
  currentSiteId: parseInt(window.sessionStorage.getItem("currentSiteId")),
};
