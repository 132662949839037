import { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import { supabase } from "../supabaseClient";

import Chart from "react-apexcharts";

export default function Stations() {
  const [state, dispatch] = useContext(UserContext);
  const [stations, setStations] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [errorText, setError] = useState("");

  const site_id = state.currentSiteId;

  useEffect(() => {
    fetchStations();
    fetchMonthly();
  }, [state]);

  const fetchStations = async () => {
    let { data: stations, error } = await supabase
      .from("station")
      .select("*")
      .eq("site_id", site_id)
      .order("name", true);
    if (error) console.log("error", error);
    else setStations(stations);
  };

  const fetchMonthly = async () => {
    let { data: monthly, error } = await supabase
      .from("readings_monthly_site")
      .select("*")
      .eq("site_id", site_id);
    if (error) console.log("error", error);
    else setMonthly(monthly);
  };

  return (
    <div className="w-full">
      <h1 className="mb-12">Mittarit #{site_id}</h1>

      <div className="flex-grow">
        <Chart
          options={{
            chart: {
              id: "bar",
            },
            xaxis: {
              categories: monthly.map((x) => `${x.year}/${x.month}`),
            },
            height: 300,
            toolbar: {
              show: false,
            },
          }}
          series={[
            {
              name: "Kuukausikulutus",
              data: monthly.map((x) => x.sum.toFixed(2)),
            },
          ]}
          colors={["#8128DE", "#382CDD"]}
          type="bar"
        />
      </div>

      {!!errorText && <Alert text={errorText} />}
      <div className="bg-white shadow overflow-y-auto h-60 rounded-md">
        <ul>
          {stations.map((station) => (
            <Station key={station.id} station={station} />
          ))}
        </ul>
      </div>
    </div>
  );
}

const Station = ({ station }) => {
  return (
    <a href={`/station/${station.id}`}>
      <li className="w-full block cursor-pointer hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition duration-150 ease-in-out">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="text-sm leading-5 font-medium truncate">
              {station.name} {station.total} kWh [#{station.meter_serial}/
              {station.station_serial}]
            </div>
          </div>
          <div></div>
        </div>
      </li>
    </a>
  );
};

const Alert = ({ text }) => (
  <div className="rounded-md bg-red-100 p-4 my-3">
    <div className="text-sm leading-5 text-red-700">{text}</div>
  </div>
);
