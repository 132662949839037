import { useState } from "react";
import { supabase } from "./supabaseClient";

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signIn({ email });
      if (error) throw error;
      setInfo("Löydät sähköpostistasi kirjautumislinkin!");
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    } catch (error) {
      setError(error.error_description || error.message);
      setTimeout(() => {
        setError(null);
      }, 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Kirjaudu sisään
          </h2>
        </div>
        <div className="flex place-content-center ">
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <label htmlFor="email" className="sr-only">
              Sähköpostiosoitteesi
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                id="email"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border sm:text-sm rounded-md focus:outline-none focus:z-10 border-gray-300 text-gray-900 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500"
                type="email"
                placeholder="Sähköpostiosoitteesi"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {loading ? (
              <button
                disabled
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-live="polite"
              >
                Kirjautumislinkkiä lähetetään..
              </button>
            ) : (
              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-live="polite"
              >
                Lähetä kirjautumislinkki
              </button>
            )}
            {info && (
              <div
                class="px-4 py-3 leading-normal text-green-700 bg-green-100 rounded-lg"
                role="alert"
              >
                <p class="font-bold">Kirjautumislinkki lähetetty!</p>
                <p>{info}</p>
              </div>
            )}
            {error && (
              <div
                class="px-4 py-3 leading-normal text-red-100 bg-red-700 rounded-lg"
                role="alert"
              >
                <p class="font-bold">Sisäänkirjautuminen epäonnistui!</p>
                <p>{error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
